import {
  MetaSwiper,
  SafariSwiper,
  hasSomeParentTheClass,
  is_touch_enabled,
} from "./meta-settings.js";
import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";

const isTab = window.matchMedia("(max-width: 1025px)");
const isMob = window.matchMedia("(max-width: 551px)");

const vh = window.innerHeight * 0.01;

document.documentElement.style.setProperty("--vh", `${vh}px`);

window.addEventListener("resize", () => {
  const vh = window.innerHeight * 0.01;

  document.documentElement.style.setProperty("--vh", `${vh}px`);
});

const header = document.getElementsByClassName(`header`)[0];
// Header scroll
const scrollContainer = () => {
  return document.documentElement || document.body;
};

if (header?.classList.contains("fixed")) {
  document.addEventListener("scroll", () => {
    if (scrollContainer().scrollTop > 0) {
      header.classList.add("scrolled");
      $(".header-close-wrapper").addClass("scrolled");
    } else if (scrollContainer().scrollTop == 0) {
      header.classList.remove("scrolled");
      $(".header-close-wrapper").removeClass("scrolled");
    }
  });
}

// menu handlers

///check mobile menu show/hide condition
const mobileMenuStartPoint = +getComputedStyle(
  document.documentElement
).getPropertyValue("--mobile-menu-start-point");
let isMobileMenuEnable =
  $(window).outerWidth() <= mobileMenuStartPoint || $(".header-mobile").length;

$(".dropdown-toggle").click(function (e) {
  if (isMobileMenuEnable) {
    //close all opened sub menu
    $(".menu-item.dropdown.active .dropdown-menu").slideUp({
      complete: function () {
        $(this).closest(".dropdown").removeClass("active");
      },
    });

    //open current submenu
    $(this).closest(".menu-item.dropdown").toggleClass("active");
    if ($(this).closest(".menu-item.dropdown").hasClass("active")) {
      e.preventDefault();
      $(this).next(".dropdown-menu").slideDown();
    }
  }
});

// toggle menu handler
function menuToggle() {
  $(".menu-toggle").toggleClass("active");
  $(".navbar-nav").toggleClass("active");
  $(".header-close-wrapper").toggleClass("active");
  // LockScroll when burger open and enable when closed and enable scroll on menu
  scrollLock.getScrollState()
    ? scrollLock.disablePageScroll(document.querySelector(".navbar-nav .menu"))
    : scrollLock.enablePageScroll();
}

//menu update function
function updateMenu() {
  isMobileMenuEnable =
    $(window).outerWidth() <= mobileMenuStartPoint ||
    $(".mobile-header").length;
  if (!isMobileMenuEnable) {
    $(".dropdown-menu").css("display", "");
    $(".header-close-wrapper").removeClass("active");
    $(".menu-item.active").removeClass("active");
    $(".navbar-nav").removeClass("active");
    $(".menu-toggle").removeClass("active");
    // LockScroll when burger open and enable when closed
    scrollLock.enablePageScroll();
  }
}
$(window).on("resize orientationchange", updateMenu);
// end of toggle menu handler

$(".menu-toggle").click(menuToggle); //menu toggles
$(".header-close-wrapper").click(menuToggle); //menu toggles

const searchBtn = document.querySelector(".header__search-btn");
const searchWrap = document.querySelector(".header__search-wrap");
const searchResult = document.querySelector(".header__search-result");
const searchInput = document.querySelector(".header__search-input");

searchBtn?.addEventListener("click", () => {
  searchWrap.classList.toggle("active");
});

// searchInput.addEventListener;

// Приклад приєднання lottie на проекті (BOLD треба приєднювати тепер просто .gif)
// lottie.loadAnimation({
//   container: document.getElementById(``),
//   renderer: "svg",
//   loop: true,
//   autoplay: true,
//   path: "./js/lottie/name.json",
// });
// test

function scrollTo(selector) {
  $("html, body").animate(
    {
      scrollTop: $(selector).offset().top,
    },
    400
  );
}

const hireLink = document.querySelector("#hire-link");

hireLink?.addEventListener("click", (e) => {
  console.log(isTab);
  if (isTab.matches) {
    const linkWrap = e.currentTarget.closest(".menu-dropdown-link");

    e.preventDefault();
    linkWrap.classList.toggle("hovered");
  } else {
    const scrollSection = document.querySelector(e.currentTarget?.hash);

    if (scrollSection) {
      e.preventDefault();
      scrollTo(e.currentTarget.hash);
    }
  }
});

// For anchor link
window.addEventListener("DOMContentLoaded", () => {
  if (location.hash) {
    const scrollSection = document.querySelector(location.hash);

    if (scrollSection) {
      setTimeout(function () {
        window.scrollTo(0, 0);
      }, 1);

      setTimeout(() => {
        scrollTo(location.hash);
      }, 500);
    }
  }
});

const talkBtn = document.querySelector(".header__talk-btn-mob");

talkBtn?.addEventListener("click", menuToggle);

const autoresizeTextarea = document.querySelectorAll(".textarea-autoresize");

autoresizeTextarea.forEach((textarea) => {
  textarea.style.height = "5px";

  textarea.addEventListener("input", ({ target }) => {
    target.style.height = target.scrollHeight + "px";

    if (target.scrollHeight > parseInt(getComputedStyle(target).maxHeight)) {
      target.classList.add("scrollbar-is-show");
    } else {
      target.classList.remove("scrollbar-is-show");
    }
  });
});

const addAutoscrollObserver = (swiperDOM, swiperInstance) => {
  const observerOptions = {
    root: null,
    rootMargin: "0px",
    threshold: 0.85,
  };

  if (swiperDOM) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          swiperInstance?.autoplay.start();
        } else {
          swiperInstance?.autoplay.stop();
        }
      });
    }, observerOptions);

    observer.observe(swiperDOM);

    return observer;
  }
};

const setSwiperCentering = (swiper) => {
  const { wrapperEl, autoplay, navigation, params, el } = swiper;

  const observer = addAutoscrollObserver(el, swiper);

  if (wrapperEl.scrollWidth < document.body.clientWidth) {
    swiper.slideTo(0);
    swiper.allowTouchMove = false;
    observer.unobserve(el);
    autoplay.stop();
    wrapperEl.style.justifyContent = "center";
    wrapperEl.style.left = params.spaceBetween / 2 + "px";
    console.log(navigation.nextEl);
    navigation.nextEl.style.visibility = "hidden";
    navigation.prevEl.style.visibility = "hidden";
  } else {
    addAutoscrollObserver(el, swiper);
    swiper.allowTouchMove = true;
    wrapperEl.style.justifyContent = null;
    wrapperEl.style.left = null;
    navigation.nextEl.style.visibility = "visible";
    navigation.prevEl.style.visibility = "visible";
  }
};

var elements = document.querySelectorAll(".force-sticky");
Stickyfill.forceSticky();
Stickyfill.add(elements);

const reasonContent = document.querySelector(".reason__content");

if (reasonContent) {
  const lis = reasonContent.querySelectorAll("li");

  lis.forEach((li, idx) => {
    li.setAttribute("data-aos", "fade-left");
    li.setAttribute("data-aos-delay", idx * 200);
  });
}

// AOS
AOS.init({
  delay: 100,
  duration: 800,
});

// const partnersCards = document.querySelectorAll(".partners__card");
// if (partnersCards.length) {
// 	partnersCards.forEach((item, idx) => {
// 		item.setAttribute("data-aos-delay", 250 * idx);
// 	});
// }

// Atropos
const atroposElems = document.querySelectorAll("[data-atropos]");
const atroposEl = document.querySelector(
  ".first-section__main-img-wrap[data-atropos]"
);

atroposElems.forEach((el) => {
  Atropos({
    el,
  });
});

// const typedOptions = {
//   strings: [
//     "building high performing teams",
//     "storytellers",
//     "disruptively different",
//   ],
//   loop: true,
//   typeSpeed: 100,
//   backSpeed: 40,
//   backDelay: 1000,
// };

// const typingEl = document.querySelector(".js-typing");

// if (typingEl) {
//   const typed = new Typed(".js-typing", typedOptions);
// }
const typingEl = document.querySelector(".js-typing");

if (typingEl) {
  const typed = new Typed("#typed", {
    stringsElement: "#typed-strings",
    loop: true,
    typeSpeed: 100,
    backSpeed: 40,
    backDelay: 1000,
  });
}

// Popup
// const teammatePopup = document.querySelector("#popup-teammate");
// const squadSection = document.querySelector(".squad");

// if (squadSection && teammatePopup) {
// 	const teammateCards = squadSection.querySelectorAll(".teammate-card");

// 	teammateCards.forEach((card) => {
// 		const btn = card.querySelector(".teammate-card__btn");

// 		btn.addEventListener("click", () => {
// 			teammatePopup.classList.remove("hidden");
// 		});
// 	});
// }

// Accordions
const verticalAccordion = document.getElementsByClassName("accordion")[0];

if (verticalAccordion) {
  const verticalAccordionItemsArr =
    verticalAccordion.querySelectorAll(".accordion__item");

  verticalAccordionItemsArr.forEach((item) => {
    const closeBtn = item.querySelector(".accordion__info-block-minus");

    item.addEventListener("click", () => {
      document
        .querySelector(".accordion__item.active")
        ?.classList.remove("active");
      item.classList.add("active");
    });

    closeBtn.addEventListener("click", (e) => {
      e.stopPropagation();
      item.classList.remove("active");
    });
  });
}

const accordionV2 = document.querySelector(".accordion-v2");

if (accordionV2) {
  const accordionItems = accordionV2.querySelectorAll(".accordion-v2__item");
  const accordionItemsContentWrap = accordionV2.querySelectorAll(
    ".accordion-v2__item-content-wrap"
  );

  accordionItems.forEach((item) => {
    item.addEventListener("click", () => {
      item.classList.toggle("active");
      // AOS refresh after accordion animation for
      // correct AOS position working
      setTimeout(() => AOS.refresh(), 250);
    });
  });

  // accordionItems[0].classList.add("active");
  // setTimeout(() => AOS.refresh(), 250);
  accordionItemsContentWrap.forEach((item) => {
    item.addEventListener("click", (e) => {
      e.stopPropagation();
    });
  });
}

$(".js-select-2").select2({
  placeholder: "Hiring talent",
  minimumResultsForSearch: Infinity,
});

// Swipers
const contactSwiperDOM = document.querySelector(".contact-us__form-swiper");

if (contactSwiperDOM) {
  const textarea = contactSwiperDOM.querySelector(".textarea-autoresize");
  const prevBtn = document.querySelector(".contact-us__form-swiper-btn.prev");
  const nextBtn = document.querySelector(".contact-us__form-swiper-btn.next");
  const submitBtn = document.querySelector(".contact-us__submit-btn");

  const contactSwiper = new Swiper(".contact-us__form-swiper", {
    spaceBetween: 10,
    allowTouchMove: false,
    autoHeight: true,
    navigation: {
      // prevEl: ".contact-us__form-swiper-btn.prev",
      // nextEl: ".contact-us__form-swiper-btn.next",
    },
    on: {
      init(e) {
        if (e.realIndex === 0) {
          prevBtn.classList.add("swiper-button-disabled");
        } else {
          prevBtn.classList.remove("swiper-button-disabled");
        }

        if (e.realIndex >= e.slides.length - 1) {
          nextBtn.classList.add("swiper-button-disabled");
        } else {
          nextBtn.classList.remove("swiper-button-disabled");
        }
      },
      slideChangeTransitionEnd(e) {
        if (e.realIndex === 0) {
          prevBtn.classList.add("swiper-button-disabled");
        } else {
          prevBtn.classList.remove("swiper-button-disabled");
        }

        if (e.realIndex >= e.slides.length - 1) {
          nextBtn.style.display = "none";
          submitBtn.style.display = "flex";
        } else {
          nextBtn.style.display = "flex";
          submitBtn.style.display = "none";
        }
      },
    },
  });

  prevBtn.addEventListener("click", () => {
    contactSwiper.slidePrev();
  });

  nextBtn.addEventListener("click", () => {
    const currentSlideDOM = contactSwiper.slides[contactSwiper.realIndex];

    const { value } =
      currentSlideDOM.querySelector(".input") ||
      currentSlideDOM.querySelector(".textarea") ||
      currentSlideDOM.querySelector(".js-select-2");

    if (value) {
      currentSlideDOM.classList.remove("error");

      contactSwiper.slideNext();
    } else {
      currentSlideDOM.classList.add("error");
    }
  });

  textarea.addEventListener("input", () => {
    console.log(contactSwiper);
    contactSwiper.updateAutoHeight(300);
  });

  SafariSwiper(contactSwiper);
}

const partnersSwiperDOM = document.querySelector(".partners__swiper");

if (partnersSwiperDOM) {
  const cards = partnersSwiperDOM.querySelectorAll(".partners__card");

  cards.forEach((card) => {
    if (!is_touch_enabled()) {
      card.addEventListener("mouseover", () => {
        const activeCard = partnersSwiperDOM.querySelector(
          ".swiper-slide-active .partners__card:first-child"
        );

        activeCard.classList.add("unhovered");
      });
      window.addEventListener("mousemove", (e) => {
        if (!hasSomeParentTheClass(e.target, "partners__card")) {
          const activeCard = partnersSwiperDOM.querySelector(
            ".swiper-slide-active .partners__card:first-child"
          );

          activeCard.classList.remove("unhovered");
        }
      });
    }
  });

  const partnersSwiper = new Swiper(partnersSwiperDOM, {
    spaceBetween: 8,
    effect: "creative",
    grabCursor: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    rewind: true,
    // loop: true,
    // loopedSlides: 150,

    // loopedSlides: 5,
    // loopPreventsSliding: false,
    // loop: true,
    navigation: {
      prevEl: ".partners__swiper-btn.prev",
      nextEl: ".partners__swiper-btn.next",
    },

    breakpoints: {
      320: {
        slidesPerView: 2,
        creativeEffect: {
          limitProgress: 5,
          prev: {
            translate: ["calc(-100% - 8px)", 0, 0],
          },
          next: {
            translate: ["calc(100% + 8px)", 0, 0],
          },
        },
      },
      769: {
        slidesPerView: 1,
        effect: "creative",
        creativeEffect: {
          limitProgress: 10,
          prev: { translate: [0, 0, -100], opacity: 0 },
          next: {
            translate: ["calc(100% + 8px)", 0, 0],
          },
        },
      },
    },

    on: {
      slideChange(swiper) {
        const isDesktop = matchMedia("(min-width: 901px)").matches;

        if (!isDesktop) return;

        const lgDesktop = matchMedia("(min-width: 1380px)").matches;
        const smDesktop = matchMedia("(min-width: 1130px)").matches;
        const slidesCount = lgDesktop ? 4 : smDesktop ? 3 : 2;

        if (swiper.isEnd) {
          swiper.slideTo(swiper.slides.length - slidesCount);
        }

        if (swiper.slides.length - swiper.activeIndex <= slidesCount - 1) {
          swiper.slideTo(0);
        }
      },
    },
  });

  SafariSwiper(partnersSwiper);
  addAutoscrollObserver(partnersSwiperDOM, partnersSwiper);
}

const askClientSection = document.querySelector(".ask-client");

if (askClientSection) {
  const askClientLogoSwiper = new Swiper(".ask-client-swiper-logo", {
    slidesPerView: "auto",
    slideToClickedSlide: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    effect: "creative",
    creativeEffect: {
      limitProgress: Infinity,
      prev: { translate: [0, 0, -400] },
      next: { translate: ["100%", 0, 0] },
    },
    navigation: {
      prevEl: ".ask-client-swiper__btn.prev",
      nextEl: ".ask-client-swiper__btn.next",
    },
    // rewind: true,
    breakpoints: {
      550: {
        creativeEffect: {
          limitProgress: Infinity,
          prev: { translate: [0, 0, -400] },
          next: { translate: ["80%", 0, 0] },
        },
      },
    },
  });

  const askClientTestimonialSwiper = new Swiper(
    ".ask-client-testimonial-swiper",
    {
      spaceBetween: 54,
      slidesPerView: 1,
      effect: "creative",
      creativeEffect: {
        prev: {
          translate: ["-100%", 0, -400],
          opacity: 0,
        },
        next: {
          translate: ["100%", 0, -400],
          opacity: 0,
        },
      },
      pagination: {
        el: ".ask-client-testimonial-swiper__slide-count",
        type: "fraction",
      },
      navigation: {
        prevEl: ".ask-client-testimonial-swiper__nav-btn.prev",
        nextEl: ".ask-client-testimonial-swiper__nav-btn.next",
      },
      // rewind: true,
    }
  );

  askClientLogoSwiper.controller.control = askClientTestimonialSwiper;
  askClientTestimonialSwiper.controller.control = askClientLogoSwiper;

  SafariSwiper(askClientLogoSwiper);
  SafariSwiper(askClientTestimonialSwiper);

  addAutoscrollObserver(
    document.querySelector(".ask-client-swiper-logo"),
    askClientLogoSwiper
  );
}

const growthKitSwiperDOM = document.querySelector(".growth-kit__card-list");

if (growthKitSwiperDOM) {
  let growthKitSwiper = null;
  const mobMatchMedia = window.matchMedia("(max-width: 500px)");

  function toggleSwiper(mobMatchMedia) {
    if (mobMatchMedia.matches) {
      growthKitSwiper = new Swiper(growthKitSwiperDOM, {
        slidesPerView: "auto",
        spaceBetween: 16,
        rewind: true,
      });

      SafariSwiper(growthKitSwiper);
      addAutoscrollObserver(growthKitSwiperDOM, growthKitSwiper);
    } else {
      growthKitSwiper?.destroy(true, true);
      growthKitSwiper = null;
    }
  }

  toggleSwiper(mobMatchMedia);

  if (navigator.sayswho === "Safari 12" || navigator.sayswho === "Safari 13") {
    mobMatchMedia?.addListener(toggleSwiper);
  } else {
    mobMatchMedia?.addEventListener("change", toggleSwiper);
  }
}

const communitySwiperDOM = document.querySelector(".community-swiper");

if (communitySwiperDOM) {
  const communitySwiper = new Swiper(communitySwiperDOM, {
    // spaceBetween: 30,
    slidesPerView: 1,
    rewind: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: ".community-swiper__btn.prev",
      nextEl: ".community-swiper__btn.next",
    },
    effect: "creative",
    creativeEffect: {
      limitProgress: Infinity,
      prev: {
        translate: [0, 0, -400],
        opacity: 0,
      },
      next: {
        translate: ["calc(100% + 16px)", 0, 0],
      },
    },

    breakpoints: {
      768: {
        effect: "creative",
        creativeEffect: {
          limitProgress: Infinity,
          prev: {
            translate: [0, 0, -400],
            opacity: 0,
          },
          next: {
            translate: ["calc(100% + 30px)", 0, 0],
          },
        },
      },
    },
  });

  SafariSwiper(communitySwiper);
  addAutoscrollObserver(communitySwiperDOM, communitySwiper);
}
const resultsSwiperDOM = document.querySelector(".results-swiper");

if (resultsSwiperDOM) {
  const resultsSwiper = new Swiper(resultsSwiperDOM, {
    // spaceBetween: 30,
    slidesPerView: 1,
    rewind: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: ".results-swiper__btn.prev",
      nextEl: ".results-swiper__btn.next",
    },
    effect: "creative",
    creativeEffect: {
      limitProgress: Infinity,
      prev: {
        translate: [0, 0, -400],
        opacity: 0,
      },
      next: {
        translate: ["calc(100% + 16px)", 0, 0],
      },
    },

    breakpoints: {
      768: {
        effect: "creative",
        creativeEffect: {
          limitProgress: Infinity,
          prev: {
            translate: [0, 0, -400],
            opacity: 0,
          },
          next: {
            translate: ["calc(100% + 20px)", 0, 0],
          },
        },
      },
    },
  });

  SafariSwiper(resultsSwiper);
  addAutoscrollObserver(resultsSwiperDOM, resultsSwiper);
}

const vacanciesSwiperDOM = document.querySelector(".vacancies-swiper");

if (vacanciesSwiperDOM) {
  const vacanciesSwiper = new Swiper(vacanciesSwiperDOM, {
    // spaceBetween: 30,
    slidesPerView: 1,
    rewind: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: ".vacancies-swiper__btn.prev",
      nextEl: ".vacancies-swiper__btn.next",
    },
    effect: "creative",
    creativeEffect: {
      limitProgress: Infinity,
      prev: {
        translate: [0, 0, -400],
        opacity: 0,
      },
      next: {
        translate: ["calc(100% + 16px)", 0, 0],
      },
    },

    breakpoints: {
      768: {
        effect: "creative",
        creativeEffect: {
          limitProgress: Infinity,
          prev: {
            translate: [0, 0, -400],
            opacity: 0,
          },
          next: {
            translate: ["calc(100% + 30px)", 0, 0],
          },
        },
      },
    },
  });

  SafariSwiper(vacanciesSwiper);
  addAutoscrollObserver(vacanciesSwiperDOM, vacanciesSwiper);
}

const successStoriesSwiperDOM = document.querySelector(
  ".success-stories-swiper"
);

if (successStoriesSwiperDOM) {
  const successStoriesSwiper = new Swiper(successStoriesSwiperDOM, {
    // slidesPerView: 1,
    slidesPerView: "auto",
    spaceBetween: 16,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    loop: true,
    navigation: {
      prevEl: ".success-stories-swiper__btn.prev",
      nextEl: ".success-stories-swiper__btn.next",
    },
    breakpoints: {
      500: {
        slidesPerView: "auto",
        loop: false,
        rewind: true,
      },
    },
    on: {
      afterInit(swiper) {
        setSwiperCentering(swiper);
        window.addEventListener("orientationchange", () => {
          setSwiperCentering(swiper);
        });
      },
    },
  });

  SafariSwiper(successStoriesSwiper);
}

const testimonialsSwiperDOM = document.querySelector(".testimonials-swiper");

if (testimonialsSwiperDOM) {
  const testimonialsSwiper = new Swiper(testimonialsSwiperDOM, {
    slidesPerView: 1,
    navigation: {
      prevEl: ".testimonials-swiper__btn.prev",
      nextEl: ".testimonials-swiper__btn.next",
    },
    rewind: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    effect: "creative",
    creativeEffect: {
      prev: {
        translate: ["calc(-100% - 30px)", 0, 0],
        scale: 0.73,
        origin: "right",
      },
      next: {
        translate: ["calc(100% + 30px)", 0, 0],
        scale: 0.73,
        origin: "left",
      },
    },
  });

  SafariSwiper(testimonialsSwiper);
  addAutoscrollObserver(testimonialsSwiperDOM, testimonialsSwiper);
}

const tagListSwiperDOM = document.querySelector(".insights__tag-list");

if (tagListSwiperDOM) {
  const tagListSwiper = new Swiper(tagListSwiperDOM, {
    slidesPerView: "auto",
    freeMode: true,
    spaceBetween: 6,
    breakpoints: {
      768: {
        spaceBetween: 8,
      },
    },
  });

  SafariSwiper(tagListSwiper);
  addAutoscrollObserver(tagListSwiperDOM, tagListSwiper);
}

const galleryImgSwiperDOM = document.querySelector(".gallery-swiper-img");

if (galleryImgSwiperDOM) {
  const galleryImgSwiper = new Swiper(galleryImgSwiperDOM, {
    slidesPerView: 1,
    loop: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: ".gallery-swiper__nav-btn.prev",
      nextEl: ".gallery-swiper__nav-btn.next",
    },
  });
  const galleryTitleSwiper = new Swiper(".gallery-swiper-title", {
    slidesPerView: 1,
    loop: true,
  });

  galleryImgSwiper.controller.control = galleryTitleSwiper;
  galleryTitleSwiper.controller.control = galleryImgSwiper;

  SafariSwiper(galleryImgSwiper);
  SafariSwiper(galleryTitleSwiper);
  addAutoscrollObserver(galleryImgSwiperDOM, galleryImgSwiper);
}

const squadSwiperDOM = document.querySelector(".squad-swiper");

if (squadSwiperDOM) {
  const squadSwiper = new Swiper(squadSwiperDOM, {
    slidesPerView: "auto",
    spaceBetween: 16,
    rewind: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: ".squad-swiper__btn.prev",
      nextEl: ".squad-swiper__btn.next",
    },
    breakpoints: {
      551: {
        spaceBetween: 40,
      },
    },
    on: {
      afterInit(swiper) {
        setSwiperCentering(swiper);
        window.addEventListener("orientationchange", () => {
          setSwiperCentering(swiper);
        });
      },
    },
  });

  SafariSwiper(squadSwiper);
}

const teamTestimonialsSwiperDOM = document.querySelector(
  ".teammate-testimonials-swiper"
);

if (teamTestimonialsSwiperDOM) {
  const teamTestimonialsSwiper = new Swiper(teamTestimonialsSwiperDOM, {
    slidesPerView: "auto",
    rewind: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    navigation: {
      prevEl: ".teammate-testimonials-swiper__btn.prev",
      nextEl: ".teammate-testimonials-swiper__btn.next",
    },
    pagination: {
      type: "fraction",
      el: ".teammate-testimonials-swiper__pagination",
    },
    // rewind: true,
    effect: "creative",
    creativeEffect: {
      prev: {
        translate: ["calc(-100% - 48px)", 0, 0],
        scale: 0.73,
        origin: "right",
      },
      next: {
        translate: ["calc(100% + 48px)", 0, 0],
        scale: 0.73,
        origin: "left",
      },
    },
  });

  SafariSwiper(teamTestimonialsSwiper);
  addAutoscrollObserver(teamTestimonialsSwiperDOM, teamTestimonialsSwiper);
}

// History cards slider
const historyCardsSwiperDOM = document.querySelector(".history-swiper");

if (historyCardsSwiperDOM) {
  const historyCardsSwiper = new Swiper(historyCardsSwiperDOM, {
    slidesPerView: 1,
    spaceBetween: 16,
    speed: 800,
    // loop: true,
    rewind: true,
    slideToClickedSlide: true,

    navigation: {
      prevEl: ".history-card-swiper__btn.prev",
      nextEl: ".history-card-swiper__btn.next",
    },

    effect: "creative",
    creativeEffect: {
      limitProgress: Infinity,
      prev: {
        translate: [0, 0, -464],
        opacity: 0,
      },
      next: {
        translate: ["calc(100% + 16px)", 0, 0],
        opacity: 1,
      },
    },

    breakpoints: {
      500: {
        slidesPerView: "auto",
        // loop: false,
        // rewind: true,
      },

      768: {
        spaceBetween: 104,

        creativeEffect: {
          limitProgress: Infinity,
          prev: {
            translate: [0, 0, -464],
            opacity: 0,
          },
          next: {
            translate: ["calc(100% + 104px)", 0, 0],
            opacity: 1,
          },
        },
      },
    },
  });

  SafariSwiper(historyCardsSwiper);
  addAutoscrollObserver(historyCardsSwiperDOM, historyCardsSwiper);
}

// Values slider
const valuesSwiperDOM = document.querySelector(".values-swiper");

if (valuesSwiperDOM) {
  const valuesSwiper = new Swiper(valuesSwiperDOM, {
    slidesPerView: "auto",
    spaceBetween: 16,
    rewind: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
    },
    loop: true,
    navigation: {
      prevEl: ".synchro-values-swiper__btn.prev",
      nextEl: ".synchro-values-swiper__btn.next",
    },
    breakpoints: {
      500: {
        loop: false,
        slidesPerView: "auto",
      },
    },
    on: {
      afterInit(swiper) {
        setSwiperCentering(swiper);
        window.addEventListener("orientationchange", () => {
          setSwiperCentering(swiper);
        });
      },
    },
  });

  SafariSwiper(valuesSwiper);
}

// MAP
const mapSection = document.querySelector(".map");

if (mapSection) {
  // MAP CHART
  const setChart = (countryChartEl) => {
    const chartWrap = countryChartEl.querySelector(".map__chart");
    const chartCanvasWrap = countryChartEl.querySelector(
      ".map__chart-canvas-wrap"
    );
    const chartChartTable = countryChartEl.querySelector(".map__chart-table");
    const chartTableTech = countryChartEl.querySelector(
      ".map__chart-table-item-list[data-type='technology']"
    );
    const chartTableDigital = countryChartEl.querySelector(
      ".map__chart-table-item-list[data-type='digital']"
    );
    const chartTableCreative = countryChartEl.querySelector(
      ".map__chart-table-item-list[data-type='creative']"
    );

    const chartTableTechItems = chartTableTech.querySelectorAll(
      ".map__chart-table-item"
    );
    const chartTableDigitalItems = chartTableDigital.querySelectorAll(
      ".map__chart-table-item"
    );
    const chartTableCreativeItems = chartTableCreative.querySelectorAll(
      ".map__chart-table-item"
    );
    // set only number for data-percentage
    [
      ...chartTableTechItems,
      ...chartTableDigitalItems,
      ...chartTableCreativeItems,
    ].forEach((item) => {
      item.setAttribute(
        "data-percentage",
        item
          .getAttribute("data-percentage")
          .replace(/^\D+/g, "")
          .match(/\d+/)[0]
      );
    });
    const percDOM = countryChartEl.querySelector(".map__chart-perc");
    const percTextDOM = countryChartEl.querySelector(".map__chart-perc-text");

    countryChartEl.addEventListener("click", (e) => {
      if (
        matchMedia("(max-width: 768px)").matches &&
        e.target === e.currentTarget
      ) {
        activeChart?.classList.remove("is-open");

        scrollLock.enablePageScroll();
      }
    });

    const BACKGROUND_COLORS = [
      "rgba(70, 34, 124, 1)",
      "rgba(185, 25, 170, 1)",
      "rgba(229, 20, 103, 1)",
      "rgba(34, 40, 55, 1)",
      "rgba(42, 250, 207, 1)",
    ];
    const BACKGROUND_HOVER_COLORS = [
      "rgba(70, 34, 124, 0.75)",
      "rgba(185, 25, 170, 0.75)",
      "rgba(229, 20, 103, 0.75)",
      "rgba(34, 40, 55, 0.75)",
      "rgba(42, 250, 207, 0.75)",
    ];
    const BACKGROUND_ACTIVE_COLORS = [
      "rgba(70, 34, 124, 0.3)",
      "rgba(185, 25, 170, 0.3)",
      "rgba(229, 20, 103, 0.3)",
      "rgba(34, 40, 55, 0.3)",
      "rgba(42, 250, 207, 0.3)",
    ];

    const handleTabChange = (type) => {
      activeTable.classList.remove("active");

      activeTable = getTable(type);

      activeTable.classList.add("active");

      chart.data = getData(type);

      if (chart.data.labels.length) {
        chart.update();
        chartCanvasWrap.style.display = "flex";
        chartChartTable.style.display = "block";
      } else {
        chartCanvasWrap.style.display = "none";
        chartChartTable.style.display = "none";
      }

      chart.update();

      [...countryChartEl.querySelectorAll("[data-simplebar]")].forEach((el) => {
        const simpleBar = SimpleBar.instances.get(el);

        simpleBar?.recalculate();
      });
    };

    const getData = (type) => {
      switch (type) {
        case "technology":
          return dataTech;
        case "digital":
          return dataDigital;
        case "creative":
          return dataCreative;
        default:
          break;
      }
    };
    const getTable = (type) => {
      switch (type) {
        case "technology":
          return chartTableTech;
        case "digital":
          return chartTableDigital;
        case "creative":
          return chartTableCreative;
        default:
          break;
      }
    };

    const tabBtns = countryChartEl.querySelectorAll(".map__chart-tab");

    let activeTab = tabBtns[0];
    let activeTable = getTable(tabBtns[0].dataset.type);

    // activeTab.classList.add("active");

    const dataTech = [...chartTableTechItems].reduce(
      (acc, item) => {
        const { title, percentage } = item.dataset;
        acc.labels.push(title);
        acc.datasets[0].data.push(percentage);
        return acc;
      },
      {
        labels: [],
        datasets: [
          {
            activeData: null,
            data: [],
            backgroundColor: [...BACKGROUND_COLORS],
            hoverBackgroundColor: [...BACKGROUND_HOVER_COLORS],
            borderColor: "rgb(255, 255, 255)",
            hoverBorderColor: "rgb(255, 255, 255)",
            borderWidth: 5,
          },
        ],
      }
    );

    const dataDigital = [...chartTableDigitalItems].reduce(
      (acc, item) => {
        const { title, percentage } = item.dataset;
        acc.labels.push(title);
        acc.datasets[0].data.push(percentage);
        return acc;
      },
      {
        labels: [],
        datasets: [
          {
            activeData: null,
            data: [],
            backgroundColor: [...BACKGROUND_COLORS],
            hoverBackgroundColor: [...BACKGROUND_HOVER_COLORS],
            borderColor: "rgb(255, 255, 255)",
            hoverBorderColor: "rgb(255, 255, 255)",
            borderWidth: 5,
          },
        ],
      }
    );

    const dataCreative = [...chartTableCreativeItems].reduce(
      (acc, item) => {
        const { title, percentage } = item.dataset;
        acc.labels.push(title);
        acc.datasets[0].data.push(percentage);
        return acc;
      },
      {
        labels: [],
        datasets: [
          {
            activeData: null,
            data: [],
            backgroundColor: [...BACKGROUND_COLORS],
            hoverBackgroundColor: [...BACKGROUND_HOVER_COLORS],
            borderColor: "rgb(255, 255, 255)",
            hoverBorderColor: "rgb(255, 255, 255)",
            borderWidth: 5,
          },
        ],
      }
    );

    const width = chartWrap.clientWidth;
    const height = chartWrap.clientHeight;

    const activeDataRender = {
      id: "activeDataRender",
      beforeInit(chart) {
        if (!chart.config.data.datasets[0].activeData) {
          chart.config.data.datasets[0].backgroundColor[0] =
            BACKGROUND_ACTIVE_COLORS[0];
          chart.config.data.datasets[0].hoverBackgroundColor[0] =
            BACKGROUND_ACTIVE_COLORS[0];
        }
      },
      beforeUpdate(chart) {
        if (!chart.config.data.datasets[0].activeData) {
          chart.config.data.datasets[0].backgroundColor[0] =
            BACKGROUND_ACTIVE_COLORS[0];
          chart.config.data.datasets[0].hoverBackgroundColor[0] =
            BACKGROUND_ACTIVE_COLORS[0];
        }
      },

      afterDraw(chart, args, options) {
        // const {
        //   ctx,
        //   // chartArea: { width, height },
        // } = chart;
        const ctx = chart.canvas.getContext("2d");
        ctx.save();

        if (!chart.config.data.datasets[0].activeData) {
          const procentage = chart.config.data.datasets[0].data[0];
          const specialisms = chart.config.data.labels[0];

          chart.config.data.datasets[0].activeData = {
            procentage,
            specialisms,
            idx: 0,
          };
        }

        const { procentage, specialisms, idx } =
          chart.config.data.datasets[0].activeData;

        // chart.config.data.datasets[0].backgroundColor[idx] =
        // 	BACKGROUND_ACTIVE_COLORS[idx];
        // chart.config.data.datasets[0].hoverBackgroundColor[idx] =
        // 	BACKGROUND_ACTIVE_COLORS[idx];

        if (!window.matchMedia("(max-width: 1281px)").matches) {
          ctx.font = "700 36px Effra";
          ctx.fillStyle = "rgb(34, 40, 55)";
          ctx.textAlign = "center";
          chart.ctx.textBaseline = "middle";
          let metrics = ctx.measureText(procentage);
          let actualHeight =
            metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
          ctx.fillText(
            procentage + "%",
            width / 2,
            height / 2 - actualHeight / 2,
            136
          );

          ctx.font = "500 14px Effra";
          ctx.fillStyle = "rgb(34, 40, 55)";
          ctx.textAlign = "center";
          chart.ctx.textBaseline = "middle";
          const wrapText = function (ctx, text, x, y, maxWidth, lineHeight) {
            let words = text.split(" ");
            let line = "";
            let testLine = "";
            let lineArray = [];

            for (var n = 0; n < words.length; n++) {
              testLine += `${words[n]} `;
              let metrics = ctx.measureText(testLine);
              let testWidth = metrics.width;

              if (testWidth > maxWidth && n > 0) {
                lineArray.push([line, x, y]);

                y += lineHeight;

                line = `${words[n]} `;
                testLine = `${words[n]} `;
              } else {
                line += `${words[n]} `;
              }

              if (n === words.length - 1) {
                lineArray.push([line, x, y]);
              }
            }
            // Return the line array
            return lineArray;
          };
          const text = wrapText(
            ctx,
            specialisms.replace(/\s{2,}/g, " "),
            width / 2,
            height / 2 + actualHeight * 2,
            100,
            14
          );

          metrics = ctx.measureText(...text);
          actualHeight =
            metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;

          // ctx.fillText(text, width / 2, height / 2 + actualHeight * 2, 136);
          text.forEach((item) => {
            ctx.fillText(item[0], item[1], item[2] - 12 * text.length);
          });
        } else {
          const { procentage, specialisms } =
            chart.config.data.datasets[0].activeData;

          percDOM.textContent = procentage + "%";
          percTextDOM.textContent = specialisms;
        }
      },
    };

    const config = {
      type: "doughnut",
      data: getData(activeTab.dataset.type),
      plugins: [activeDataRender],
      options: {
        spacing: 7,
        cutoutPercentage: 75,
        // borderWidth: 22,
        responsive: true,
        borderColor: "#ffffff",
        onHover({ target: canvas }, elements) {
          // cursor pointer for elements hover
          canvas.style.cursor = elements.length ? "pointer" : "default";
        },
        onClick({ target }, elements) {
          // const {
          //   ctx,
          //   // chartArea: { width, height },
          // } = chart;
          const ctx = target.getContext("2d");

          ctx.save();

          if (elements.length) {
            const procentage =
              chart.config.data.datasets[0].data[elements[0]._index];
            const specialisms = chart.config.data.labels[elements[0]._index];

            chart.config.data.datasets[0].backgroundColor =
              chart.config.data.datasets[0].backgroundColor.map(
                (color, idx) => {
                  return BACKGROUND_COLORS[idx];
                }
              );

            chart.config.data.datasets[0].hoverBackgroundColor =
              chart.config.data.datasets[0].hoverBackgroundColor.map(
                (color, idx) => {
                  return BACKGROUND_HOVER_COLORS[idx];
                }
              );

            chart.config.data.datasets[0].backgroundColor[elements[0]._index] =
              BACKGROUND_ACTIVE_COLORS[elements[0]._index];
            chart.config.data.datasets[0].hoverBackgroundColor[
              elements[0]._index
            ] = BACKGROUND_ACTIVE_COLORS[elements[0]._index];

            chart.config.data.datasets[0].activeData = {
              procentage,
              specialisms,
              idx: elements[0]._index,
            };

            if (!window.matchMedia("(max-width: 1281px)").matches) {
              ctx.font = "700 36px Effra";
              ctx.fillStyle = "rgb(34, 40, 55)";
              ctx.textAlign = "center";
              chart.ctx.textBaseline = "middle";
              let metrics = ctx.measureText(
                chart.config.data.datasets[0].activeData.procentage
              );
              let actualHeight =
                metrics.actualBoundingBoxAscent +
                metrics.actualBoundingBoxDescent;
              ctx.fillText(
                chart.config.data.datasets[0].activeData.procentage + "%",
                width / 2,
                height / 2 - actualHeight / 2,
                136
              );

              // ctx.font = "500 14px Effra";
              // ctx.fillStyle = "rgb(34, 40, 55)";
              // ctx.textAlign = "center";
              // chart.ctx.textBaseline = "middle";
              // // metrics = ctx.measureText(
              // //   chart.config.data.datasets[0].activeData.specialisms
              // // );
              // // actualHeight =
              // //   metrics.actualBoundingBoxAscent +
              // //   metrics.actualBoundingBoxDescent;

              // // ctx.fillText(
              // //   chart.config.data.datasets[0].activeData.specialisms,
              // //   width / 2,
              // //   height / 2 + actualHeight * 2,
              // //   136
              // // );
            } else {
              percDOM.textContent = procentage + "%";
              percTextDOM.textContent = specialisms;
            }
            chart.update();
          }
        },
        // events: ["click"],
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
        plugins: {},
      },
    };

    const chart = new Chart(countryChartEl.querySelector(".chart"), config);

    handleTabChange(activeTab.dataset.type);

    tabBtns.forEach((btn) => {
      btn.addEventListener("click", () => {
        if (activeTab === btn) return;

        handleTabChange(btn.dataset.type);

        activeTab.classList.remove("active");

        activeTab = btn;

        activeTab.classList.add("active");
      });
    });
  };

  const countryCharts = document.querySelectorAll(".map__chart-wrap");

  countryCharts.forEach(setChart);

  let activeChart = null;

  const getChart = (country) => {
    return [...countryCharts].find((item) => item.dataset.country === country);
  };

  // Offices info
  const officesTitle = document.querySelector(".map__offices-title");
  const officesText = document.querySelector(".map__offices-offices-text");

  // MAP
  const setMap = () => {
    try {
      const map = mapSection
        .querySelector(".map__wrap")
        .contentDocument.querySelector("svg");

      const moveUkBtn = document.querySelector("#to-uk");
      const moveUsaBtn = document.querySelector("#to-usa");
      const moveEuBtn = document.querySelector("#to-eu");
      const openPercBtn = document.querySelector(".map__open-perc-btn");
      const closePercBtns = document.querySelectorAll(".map__chart-close-btn");

      const euCountries = [
        "#BE",
        "#PT",
        "#PL",
        "#NL",
        "#BG",
        "#IT",
        "#DE",
        "#FR",
        "#ES",
        "#HR",
        "#RO",
        "#SI",
        "#SK",
        "#CZ",
        "#AT",
        "#CH",
        "#BA",
        "#HU",
        "#RS",
        "#ME",
        "#XK",
        "#AL",
        "#MK",
        "#TR",
        "#GR",
        "#UK",
        "#MD",
        "#DK",
        "#SE",
        "#NO",
        "#FI",
        "#LT",
        "#LV",
        "#EE",
        "#BY",
      ];

      const baseViewBox = map.getAttribute("viewBox");
      const defaultCountries = [
        {
          btn: moveUkBtn,
          list: ["#GB", "#IR"],
        },
        {
          btn: moveUsaBtn,
          list: ["#CA", "#US"],
        },
        {
          btn: moveEuBtn,
          list: euCountries,
        },
      ];

      const disableAllBtn = () => {
        [moveUkBtn, moveUsaBtn, moveEuBtn].forEach((btn) =>
          btn.setAttribute("disabled", true)
        );
      };

      const enableAllBtn = () => {
        [moveUkBtn, moveUsaBtn, moveEuBtn].forEach((btn) =>
          btn.removeAttribute("disabled")
        );
      };

      map.addEventListener("click", ({ target }) => {
        // console.log(target);
        if (activeCountries.length && !activeCountries.includes(target)) {
          showAll();

          activeChart?.classList.remove("active");
          activeChart = null;
        }
      });

      const svgGrayStop1 = map.querySelector("#grayStopColor1");
      const svgGrayStop2 = map.querySelector("#grayStopColor2");
      const svgGradientStop1 = map.querySelector("#grStopColor1");
      const svgGradientStop2 = map.querySelector("#grStopColor2");

      const MIN_SIZE_DESKTOP = 200;
      const MIN_SIZE_TAB = 250;
      const MIN_SIZE_MOBILE = 160;

      let activeCountries = [];
      let activeLocationBtn = null;

      const moveTo = (selectors) => {
        disableAllBtn();
        activeCountries.forEach((country) => {
          country.style.fill = "url(#magenta-gr-2)";
          svgGrayStop1.attributes["stop-color"].value = "#E6E6E6";
          svgGrayStop2.attributes["stop-color"].value = "#E6E6E6";
          svgGradientStop1.attributes["stop-color"].value = "#B919AA";
          svgGradientStop2.attributes["stop-color"].value = "#6a208a";
        });

        const countries = selectors.map((selector) => {
          return map.querySelector(selector);
        });

        const svgBox = countries[0].getBBox();

        countries.forEach((country) => {
          const { x, y, width, height } = country.getBBox();

          if (x < svgBox.x) {
            svgBox.width += svgBox.x - x;
            svgBox.x = x;
          }
          if (y < svgBox.y) {
            svgBox.height += svgBox.y - y;
            svgBox.y = y;
          }
          if (x + width > svgBox.x + svgBox.width)
            svgBox.width = x + width - svgBox.x;
          if (y + height > svgBox.y + svgBox.height)
            svgBox.height = y + height - svgBox.y;

          if (activeCountries.length) country.style.fill = "url(#magenta-gr)";
        });

        let minSize = isMob.matches
          ? MIN_SIZE_MOBILE
          : isTab.matches
          ? MIN_SIZE_TAB
          : MIN_SIZE_DESKTOP;

        if (svgBox.width < minSize) {
          svgBox.x -= (minSize - svgBox.width) / 2;
          svgBox.width = minSize;
        } else {
          // Additing X gap for big countries
          svgBox.x -= (svgBox.width * 0.3) / 2;
          svgBox.width += svgBox.width * 0.3;
        }
        if (svgBox.height < minSize) {
          svgBox.y -= (minSize - svgBox.height) / 2;
          svgBox.height = minSize;
        } else {
          // Additing Y gap for big countries
          svgBox.y -= (svgBox.height * 0.3) / 2;
          svgBox.height += svgBox.height * 0.3;
        }

        console.log(svgBox);

        // if (svgBox.y + svgBox.height > map.clientHeight) {
        // 	const diff = svgBox.y + svgBox.height - map.clientHeight;

        // 	svgBox.y -= diff;
        // }
        if (activeCountries.length) {
          gsap.to(svgGrayStop1, {
            duration: 1,
            attr: {
              "stop-color": "#B919AA",
            },
            ease: "power3.inOut",
          });
          gsap.to(svgGrayStop2, {
            duration: 1,
            attr: {
              "stop-color": "#6a208a",
            },
            ease: "power3.inOut",
          });
          gsap.to(svgGradientStop1, {
            duration: 1,
            attr: {
              "stop-color": "#E6E6E6",
            },
            ease: "power3.inOut",
          });
          gsap.to(svgGradientStop2, {
            duration: 1,
            attr: {
              "stop-color": "#E6E6E6",
            },
            ease: "power3.inOut",
          });
          gsap.to(map, {
            duration: 1,
            attr: {
              viewBox: [svgBox.x, svgBox.y, svgBox.width, svgBox.height].join(
                " "
              ),
            },
            ease: "power3.inOut",
            onComplete() {
              activeCountries.forEach((country) => {
                country.style.fill = "";
              });
              activeCountries = countries;
              enableAllBtn();
            },
          });
        } else {
          disableAllBtn();
          gsap.to(map, {
            duration: 1,
            attr: {
              viewBox: [svgBox.x, svgBox.y, svgBox.width, svgBox.height].join(
                " "
              ),
            },
            ease: "power3.inOut",
            onComplete() {
              activeCountries.forEach((country) => {
                country.style.fill = "";
              });

              svgGrayStop1.attributes["stop-color"].value = "#B919AA";
              svgGrayStop2.attributes["stop-color"].value = "#6a208a";

              activeCountries = countries;
              activeCountries.forEach((country) => {
                country.style.fill = "url(#magenta-gr)";
              });

              gsap.to(svgGradientStop1, {
                duration: 1,
                attr: {
                  "stop-color": "#E6E6E6",
                },
                ease: "power3.inOut",
              });
              gsap.to(svgGradientStop2, {
                duration: 1,
                attr: {
                  "stop-color": "#E6E6E6",
                },
                ease: "power3.inOut",
              });
              enableAllBtn();
            },
          });
        }
      };

      const setPercBtnDisebling = () => {
        openPercBtn.disabled = !activeLocationBtn;
      };

      const changeBtnState = ({ target }) => {
        activeLocationBtn?.classList.remove("active");
        // activeLocationBtn?.removeAttribute("disabled");

        target.classList.add("active");
        // target.setAttribute("disabled", true);

        officesTitle.textContent = target.dataset.infoTitle;
        officesText.textContent = target.dataset.infoText;

        activeLocationBtn = target;

        setPercBtnDisebling();
      };

      setPercBtnDisebling();

      const setDefaultCountries = (list, addListener = true, ignored = []) => {
        // const countries = selectors.map((selector) => {
        //   return map.querySelector(selector);
        // });
        list.forEach((tabObj) => {
          tabObj.list.forEach((country) => {
            const countryPath = map.querySelector(country);

            if (!ignored.includes(country)) {
              countryPath.style.fill = "url(#magenta-gr-2)";
              countryPath.style.cursor = "pointer";
            }

            if (addListener) {
              countryPath.addEventListener("click", () => {
                // moveTo(countryList);
                if (!activeCountries.length) tabObj.btn.click();
              });
            }
          });
        });
      };

      setDefaultCountries(defaultCountries);

      const showAll = () => {
        disableAllBtn();

        gsap.to(map, {
          duration: 1,
          attr: {
            viewBox: baseViewBox,
          },
          ease: "power3.inOut",
          onComplete() {
            // activeCountries.forEach((country) => {
            //   country.style.fill = "";
            // });

            svgGrayStop1.attributes["stop-color"].value = "#B919AA";
            svgGrayStop2.attributes["stop-color"].value = "#6a208a";
            svgGradientStop1.attributes["stop-color"].value = "#E6E6E6";
            svgGradientStop2.attributes["stop-color"].value = "#E6E6E6";

            activeCountries.forEach((country) => {
              country.style.fill = "url(#magenta-gr)";
            });
            const activeCountriesIds = activeCountries.map(
              (item) => "#" + item.id
            );
            setDefaultCountries(defaultCountries, false, activeCountriesIds);

            // svgGradientStop1.attributes["stop-color"].value = "#B919AA";
            // svgGradientStop2.attributes["stop-color"].value = "#6a208a";

            gsap.to(svgGradientStop1, {
              duration: 1,
              attr: {
                "stop-color": "#B919AA",
              },
              ease: "power3.inOut",
            });
            gsap.to(svgGradientStop2, {
              duration: 1,
              attr: {
                "stop-color": "#6a208a",
              },
              ease: "power3.inOut",
              onComplete() {
                activeCountries.forEach((country) => {
                  country.style.fill = "url(#magenta-gr-2)";
                });

                activeCountries = [];
                activeLocationBtn?.classList.remove("active");
                activeLocationBtn = null;
                setPercBtnDisebling();
                enableAllBtn();
              },
            });
          },
        });
      };

      moveUkBtn.addEventListener("click", (e) => {
        if (e.target === activeLocationBtn) {
          showAll();
          activeChart.classList.remove("active");
          activeChart = null;
          return;
        }

        changeBtnState(e);

        if (activeChart) activeChart.classList.remove("active");

        activeChart = getChart("uk-ireland");

        activeChart.classList.add("active");

        moveTo(["#GB", "#IR"]);
      });

      moveUsaBtn.addEventListener("click", (e) => {
        if (e.target === activeLocationBtn) {
          showAll();
          activeChart.classList.remove("active");
          activeChart = null;
          return;
        }

        changeBtnState(e);

        if (activeChart) activeChart.classList.remove("active");

        activeChart = getChart("us-canada");

        activeChart.classList.add("active");

        moveTo(["#CA", "#US"]);
      });

      moveEuBtn.addEventListener("click", (e) => {
        if (e.target === activeLocationBtn) {
          showAll();
          activeChart.classList.remove("active");
          activeChart = null;
          return;
        }

        changeBtnState(e);

        if (activeChart) activeChart.classList.remove("active");

        activeChart = getChart("eu");

        activeChart.classList.add("active");

        moveTo(euCountries);
      });

      openPercBtn.addEventListener("click", () => {
        activeChart?.classList.toggle("is-open");

        if (window.matchMedia("(max-width: 769px)").matches) {
          scrollLock.getScrollState()
            ? scrollLock.disablePageScroll(
                document.querySelector(".navbar-nav .menu")
              )
            : scrollLock.enablePageScroll();
        }
      });

      closePercBtns.forEach((btn) => {
        btn.addEventListener("click", () => {
          activeChart?.classList.remove("is-open");

          scrollLock.enablePageScroll();
        });
      });
    } catch (e) {
      console.info(e);
      setTimeout(setMap, 500);
    }
  };
  const map = mapSection
    .querySelector(".map__wrap")
    ?.contentDocument.querySelector("svg");
  if (map) {
    setMap();
  } else {
    mapSection.querySelector(".map__wrap").addEventListener("load", setMap);
  }
}

gsap.registerPlugin(ScrollTrigger);

if (document.querySelector(".row")) {
  const galleryRow = gsap.utils.toArray(".row");

  galleryRow.forEach((row, i) => {
    const w = row.querySelector(".img-box");
    const [x, xEnd] = i % 2 === 0 ? ["-30%", "-60%"] : ["-60%", "-30%"];
    gsap.fromTo(
      w,
      { x },
      {
        x: xEnd,
        scrollTrigger: {
          toggleActions: "none",
          trigger: row,
          scrub: 1.5,
        },
      }
    );
  });
}

const reasonList = document.querySelector(".reason__list");

if (reasonList) {
  const reasonItems = reasonList.querySelectorAll(".reason__item");

  reasonItems.forEach((item, idx) => {
    item.setAttribute("data-aos-delay", 200 * idx);
  });
}

const specialismsSection = document.querySelector(".specialisms");

if (specialismsSection) {
  const items = specialismsSection.querySelectorAll(
    ".specialisms__additional-item"
  );
  const mainItem = specialismsSection.querySelector(".specialisms__main-item");
  const mainTitle = mainItem.querySelector(".specialisms__main-title");
  const mainText = mainItem.querySelector(".specialisms__main-text");
  const mainLink = mainItem.querySelector(".specialisms__main-btn");
  const mainImg = mainItem.querySelector(".specialisms__main-img");
  const imgLoader = mainItem.querySelector(".img-loader-wrap");

  let currentDataset = { ...mainItem.dataset };

  items.forEach((item) => {
    item.addEventListener("click", () => {
      const itemTitle = item.querySelector(
        ".specialisms__additional-item-title"
      );
      const itemText = item.querySelector(".specialisms__additional-item-text");

      const { dataset } = item;
      const duration =
        parseFloat(window.getComputedStyle(mainItem).transitionDuration) * 1000;

      mainItem.classList.add("is-hidden");
      item.classList.add("is-hidden");

      setTimeout(() => {
        mainItem.setAttribute("data-title", dataset.title);
        mainItem.setAttribute("data-short-descr", dataset.shortDescr);
        mainItem.setAttribute("data-descr", dataset.descr);
        mainItem.setAttribute("data-link", dataset.link);
        mainItem.setAttribute("data-img", dataset.img);

        item.setAttribute("data-title", currentDataset.title);
        item.setAttribute("data-short-descr", currentDataset.shortDescr);
        item.setAttribute("data-descr", currentDataset.descr);
        item.setAttribute("data-link", currentDataset.link);
        item.setAttribute("data-img", currentDataset.img);

        imgLoader.style.display = "flex";

        itemTitle.innerHTML = currentDataset.title;
        itemText.innerHTML = currentDataset.shortDescr;

        currentDataset = { ...mainItem.dataset };

        mainTitle.innerHTML = currentDataset.title;
        mainImg.setAttribute("src", currentDataset.img);
        mainImg.style.display = "none";
        mainImg.addEventListener("load", () => {
          imgLoader.style.display = "none";
          mainImg.style.display = "block";
        });

        if (mainText.hasAttribute("data-simplebar")) {
          mainText.querySelector(".simplebar-content").innerHTML =
            currentDataset.descr;
        } else {
          mainText.innerHTML = currentDataset.descr;
        }

        mainLink.setAttribute("href", currentDataset.link);
        mainItem.classList.remove("is-hidden");
        item.classList.remove("is-hidden");

        const simplebarEl = SimpleBar.instances.get(mainText);
        simplebarEl.recalculate();

        if (isTab.matches) {
          const headerHeight = document.querySelector("header").clientHeight;

          $("html, body").animate(
            {
              scrollTop: $(mainItem).offset().top - headerHeight,
            },
            400
          );
          // mainItem.scrollIntoView({
          //   behavior: "smooth",
          // });
        }
      }, duration * 1.5);
    });
  });
}

const hireBtn = document.querySelector(".hire-js");

if (hireBtn) {
  const accordionSection = document.querySelector(".accordion-section");

  if (accordionSection) {
    hireBtn.addEventListener("click", () => {
      scrollTo("#chapters");
    });
  }
}

const communityLinks = document.querySelectorAll(".community__list-link");

if (communityLinks.length) {
  communityLinks.forEach((item) => {
    item.addEventListener("mouseover", () => {
      const icon = item
        .querySelector(".community__list-item-icon object")
        .contentDocument?.querySelector("svg");

      if (!icon.style.transition) icon.style.transition = "250ms ease all";

      icon.style.fill = "#ffffff";
    });

    item.addEventListener("mouseleave", () => {
      const icon = item
        .querySelector(".community__list-item-icon object")
        .contentDocument.querySelector("svg");

      icon.style.fill = null;
    });
  });
}

const applyNowCardBtns = document.querySelectorAll(".job-card__btn-wrap");

applyNowCardBtns.forEach((btn) => {
  btn.addEventListener("click", (e) => {
    e.preventDefault();
  });
});
